.page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.page-navbar {
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
}

.page-logo {
  width: 80px;
  padding: 0.5rem;
  margin-top: 4rem;
  border-radius: 50%;
  background-color: #fff;
  z-index: 11;
}

.logo-link {
  display: flex;
}

.page-logo img {
  width: 100%;
}

.page-header-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-left: 4rem;
}

.page-header-link {
  list-style: none;
  margin-right: 2rem;
  cursor: pointer;
  transition: all 200ms ease-in;
  font-weight: 600;
}

.page-header-link:hover {
  color: #101050;
}

.page-header-link .link {
  text-align: center;
  display: inline-block;
  color: var(--dc);
  text-decoration: none;
  transition: color 300ms ease-in;
}

.page-header-link .link span {
  margin-left: 0.2rem;
}

.link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--lp);
  transition: width 300ms ease-in;
}

.link:hover::after {
  width: 100%;
  transition: width 300ms;
}

.link:hover {
  color: var(--lp);
}

.active-page {
  color: var(--lp);
}

.active-page::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: var(--lp);
  transition: width 400ms ease-in;
}

/******** MOBILE NAVIGATION ********/

.nav-toggler {
  cursor: pointer;
  display: none;
  transition: all 350ms ease-in-out;
}

.nav-toggler div {
  width: 2.5rem;
  height: 0.3rem;
  background-color: var(--mc);
  border-radius: 2px;
  transition: background 250ms ease-in-out;
}

.nav-toggler div:not(:last-child) {
  margin-bottom: 0.4rem;
}

.nav-toggler:hover div {
  background-color: var(--lp);
}

.toggle div {
  transition: all 400ms ease-in-out;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-18px, 1.5px);
}

.toggle .line2 {
  opacity: 0;
  position: relative;
  transition: all 600ms ease-in-out;
  animation: line2 600ms ease-in-out;
}

.mob-nav-bg {
  display: none;
}

.mob-nav {
  display: none;
}

@keyframes line2 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    right: -60%;
    display: none;
  }
}

.toggle .line3 {
  transform: rotate(45deg) translate(-13px, 1px);
}

/******** FOOTER SECTION*******/

.page-footer {
  background-color: var(--tc);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0 0;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  bottom: 0;
}

.page-footer > img {
  position: absolute;
  z-index: 1;
}

.page-footer > img:nth-child(1) {
  top: 8%;
  left: 6%;
  animation: footer1 8000ms infinite linear;
}

@keyframes footer1 {
  0% {
    top: 8%;
    left: 6%;
  }

  50% {
    top: 20%;
    left: 5%;
  }

  100% {
    top: 8%;
    left: 6%;
  }
}

.page-footer > img:nth-child(2) {
  top: 10%;
  right: 4%;
  animation: footer2 8000ms infinite linear;
}

@keyframes footer2 {
  0% {
    top: 10%;
    right: 4%;
  }

  50% {
    top: 20%;
    right: 8%;
  }

  100% {
    top: 10%;
    right: 4%;
  }
}

.page-footer > img:nth-child(3) {
  bottom: 8%;
  left: 13%;
  animation: footer3 8000ms infinite linear;
}

@keyframes footer3 {
  0% {
    bottom: 8%;
    left: 13%;
  }

  50% {
    bottom: 20%;
    left: 8%;
  }

  100% {
    bottom: 8%;
    left: 13%;
  }
}

.page-footer > img:nth-child(4) {
  bottom: 8%;
  right: 10%;
  animation: footer4 7000ms infinite linear;
}

@keyframes footer4 {
  0% {
    bottom: 8%;
    right: 10%;
  }

  50% {
    bottom: 6%;
    right: 5%;
  }

  100% {
    bottom: 8%;
    right: 10%;
  }
}

.footer-section {
  z-index: 2;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #000;
  padding: 2rem 0 3rem 0;
}

.footer-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-size: 2.5rem;
}

.footer-logo .logo-icon {
  width: 65px;
  height: 65px;
  margin-right: 2rem;
}

.footer-logo .logo-icon img {
  width: 100%;
  object-fit: contain;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.footer-link a {
  color: #000;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 200ms ease-in;
}

.footer-link svg {
  margin-left: 0.3rem;
  transform: rotate(-40deg);
  transition: all 200ms ease-in;
}

.footer-link:hover svg,
.footer-link:hover a {
  transform: rotate(0deg);
  color: var(--lp);
}

.footer-btm {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.footer-contact {
  display: flex;
  gap: 2.5rem;
  font-weight: 500;
  text-decoration: underline;
}

.footer-contact h5 {
  font-size: 1rem;
}

.footer-contact a {
  color: var(--grey-text-color);
  font-size: 0.85rem;
}

.footer-contact a:hover {
  color: var(--lp);
}

.footer-email {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.footer-tel {
  display: flex;
  flex-direction: column;
}

.social-media-links > h5 {
  font-weight: 500;
  text-align: end;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.footer-social-links {
  display: flex;
  padding-left: 0;
}

.footer-social-link:not(:last-child) {
  margin-right: 2.5rem;
}

.footer-social-link svg {
  font-size: 2rem;
}

.footer-location {
  padding: 0.5rem 0;
  border-top: 1px solid var(--divider);
  font-size: 0.85rem;
}

.footer-copyright {
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 0.5rem 0;
  border-top: 1px solid var(--divider);
  font-size: 0.85rem;
}

@media only screen and (max-width: 1000px) {
  .footer-top {
    flex-direction: column;
  }
  .footer-links {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .nav-toggler {
    display: block;
  }

  .mob-nav-bg {
    display: block;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    animation: mob-nav-bg 350ms linear;
  }

  @keyframes mob-nav-bg {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* .mob-nav-bg-active {
    animation: mob-bg-active 350ms linear;
  }

  @keyframes mob-bg-active {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  } */

  .mob-nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 65%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--mc);
    flex-direction: column;
    z-index: 1000;
    transform: translateX(0%);
    opacity: 1;
    animation: nav-slide 350ms linear;
  }

  @keyframes nav-slide {
    0% {
      transform: translateX(70%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .mob-nav .nav-close {
    position: absolute;
    top: 2%;
    right: 5%;
    font-size: 3rem;
    color: var(--tc);
    cursor: pointer;
    transition: color 300ms ease-in-out;
  }

  .page-header-link:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .page-header-links {
    display: none;
  }

  .mob-nav span,
  .mob-nav svg {
    color: var(--tc);
  }

  .mob-nav svg {
    margin-right: 0.3rem;
  }

  .mob-nav + .nav-toggler {
    position: fixed;
  }

  .page-navbar {
    width: 80%;
  }

  .footer-btm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-contact {
    margin-bottom: 3rem;
  }

  .social-media-links h5 {
    text-align: center;
  }
}

@media only screen and (max-width: 520px) {
  .footer-top {
    flex-direction: column;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .footer-link:not(:last-child) {
    margin: 0rem;
  }
}

@media only screen and (max-width: 400px) {
  .footer-contact {
    flex-direction: column;
  }
}

#progressBarContainer {
  position: sticky;
  z-index: 10;
  background: var(--tbgc);
  width: 100%;
  top: 0;
  left: 0;
}

#progressBar {
  background: linear-gradient(
    to left,
    var(--dc) 0%,
    var(--mc) 50%,
    var(--bc) 100%
  );
  transform-origin: top left;
  transform: scale(1, 1);
  opacity: 1;
}

#progressBarContainer,
#progressBar {
  height: 9px;
}

.flag-icon {
  height: 2.5ch;
  width: 2.5ch;
}