@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --rc: hsl(218, 57%, 30%);
  --mc: hsl(218, 57%, 25%);
  --dc: hsl(218, 57%, 10%);
  --sdc: hsl(218, 57%, 20%);
  --bc: #1e53b0;
  --lp: hsl(218, 57%, 25%);
  --bgc: hsl(218, 90%, 80%);
  --tbgc: hsl(218, 95%, 95%);
  --tc: rgb(248, 248, 248);
  --divider: rgb(235, 235, 235);
  --grey-text-color: rgb(125 125 125);
}

li {
  list-style: none;
}

*::selection {
  background-color: var(--lp);
  color: var(--tc);
}

a {
  text-decoration: none;
}

.img-wrapper img {
  width: 100%;
}

/******* MORE ABOUT PAGE CONTENTS **********/

.more-about-page .section-container {
  padding: 5rem 0 5rem;
}

.more-about-page .section-container .section-title {
  margin-bottom: 4rem;
}

.more-about-page .section-container P {
  margin: 1rem 0 2rem;
  max-width: 80ch;
}

.more-about-page .section-container ul {
  margin: 1rem 0 2rem;
}

.more-about-page .section-container li {
  list-style: decimal;
  margin-bottom: 0.5rem;
}

.more-about-page .section-container h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.more-about-page .section-container h2 {
  font-size: 1.6rem;
  font-weight: 600;
}


.text-s {
  font-size: 0.875rem;
}

.text-sm {
  font-size: 0.8125rem;
}