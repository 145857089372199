/***** Scroll ******/

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #585858;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #acacac;
}

.to-top-btn {
  position: fixed;
  bottom: 100px;
  right: 29px;
  height: 50px;
  width: 50px;
  font-size: 2.5rem;
  transition: all 300ms ease-in-out;
  border-radius: 50%;
  background-color: var(--mc);
  color: var(--tc);
  border: 2px solid var(--tc);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.to-top-btn:hover {
  background-color: var(--tc);
  color: var(--dc);
  height: 52px;
  width: 52px;
  bottom: 99px;
  right: 28px;
  border: 2px solid var(--mc);
}

.to-top-btn:focus {
  width: 48px;
  height: 48px;
  bottom: 101px;
  right: 30px;
}

.page-loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/********* NOT FOUND *********/

.not-found-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
  z-index: 100;
  background-color: #fff;
}

.not-found-section h1 {
  font-size: 5rem;
}

.not-found-section span {
  font-size: 3.5rem;
}

/******** GLOBAL *******/

.section-marker {
  padding: 0.8rem 1.2rem;
  background-color: var(--tbgc);
  display: inline-block;
  border-radius: 5px;
  color: var(--bc);
  margin-bottom: 2rem;
  font-weight: 600;
}

.section-title {
  font-size: 2.6rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
