.home-modal {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10000;
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.home-modal-bg {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-modal-content {
  /* position: relative; */
  width: 40%;
  min-height: 40%;
  max-height: auto;
  border: 1px solid black;
  padding: 2rem;
  background-color: #fff;
  border-radius: 5px;
}

.home-modal .modal-close {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 2.2rem;
  cursor: pointer;
  transition: color 250ms ease-in-out;
  color: var(--tc);
}

.home-modal .modal-close:hover {
  color: var(--lp);
}

.home-modal h1 {
  margin: 3rem auto 2rem;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .home-modal-content {
    width: 50%;
  }
}

@media only screen and (max-width: 960px) {
  .home-modal-content {
    width: 60%;
  }
}

@media only screen and (max-width: 560px) {
  .home-modal-content {
    width: 80%;
  }
}
